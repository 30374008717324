body {
  margin: 0;
  background-color: #414141;
  font-family: 'Copperplate', 'Alegreya Sans SC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  font-family: 'Copperplate', 'Alegreya Sans SC', sans-serif;
  font-size: 7vw;
}
h2 {
  font-family: 'Copperplate', 'Alegreya Sans SC', sans-serif;
  font-size: 3vw;
}
h3 {
  font-family: 'Copperplate', 'Alegreya Sans SC', sans-serif;
  font-size: 1vw;
}

#main-profile {
  border: 1px solid white;
  border-radius: 15px;
}
