.App {
  text-align: center;
  color: white
}

.CourseTile span {
  font-size: 1vw;
}

.navBar {
  text-align:center;
}
.navBar a {
  text-decoration: none;
  color: #fff;
  line-height: 3vw;
  display:inline-block;
}

.Scorecard {
  width: 50%;
  margin: auto;
  border: 1px solid white;
  border-collapse: collapse;
  background-color: rgba(22,110,20,1);
  border-radius: 25px;
}

.Scorecard input {
  width: 1.5vw;
}

.Scorecard img {
  height: 4vw
}

.Scorecard table {
  border: 1px solid white;
  border-collapse: collapse;
}

.Scorecard td, th {
  border: 1px solid white;
}

.Scorecard th {
  width: 3vw
}

#submit-scores-container > button {
  background-color: rgba(22,110,20,1);
  border: solid white 1px;
  border-radius: 15px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2vw;
}

#submit-scores-container > button:disabled{
  background-color: #414141;
  border: solid white 1px;
  border-radius: 15px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  -webkit-animation: seconds 1.0s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 3s;
  animation: seconds 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 3s;
  position: relative;
}

@-webkit-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px; 
    position: absolute;   
  }
}
@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;     
  }
}

.AvatarLink {
  cursor: pointer;
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #414141;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
